import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { LogoHalf1, LogoHalf2 } from '../assets/images';

interface PageLoadAnimationProps {
    onComplete: () => void; 
  }

const PageLoadAnimation: React.FC<PageLoadAnimationProps> = ({ onComplete }) => {
  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      onComplete: onComplete, // Call the onComplete function once the animation finishes
    });

    tl.set('.left-screen, .right-screen', { x: '0%' });

    tl.fromTo(
      '.logo',
      {  rotation: 0 },
      {
        scale: 1.2,
        rotation: 360,
        duration: 0.7,
        ease: 'elastic.out(1, 0.3)',
        repeat: 1,
        yoyo: true,
      }
    )
      .to('.logo', {
        y: -20,
        scale: 1,
        duration: 0.5,
        ease: 'power1.inOut',
        yoyo: true,
        repeat: 1,
      })
      .to('.logo', {
        rotation: 15,
        duration: 0.2,
        ease: 'power1.inOut',
        yoyo: true,
        repeat: 3,
      })
      .to('.logo', {
        scale: 1,
        opacity: 0,
        duration: 1,
        ease: 'power1.inOut',
      });

    tl.to('.left-screen, .right-screen', {
      duration: 0.5,
      opacity: 0,
      ease: 'power2.out',
    });

    gsap.delayedCall(0.5, () => tl.play());
  }, [onComplete]);

  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="left-screen w-1/2 h-full bg-white flex items-center justify-end">
        <img src={LogoHalf1} alt="Logo Half 1" className="w-20 logo" />
      </div>
      <div className="right-screen w-1/2 h-full bg-white flex items-center justify-start">
        <img src={LogoHalf2} alt="Logo Half 2" className="w-20 logo" />
      </div>
    </div>
  );
};

export default PageLoadAnimation;
