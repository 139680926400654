import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import { LocationIcon, MailIcon, PhoneIcon } from '../assets/images';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactInformation: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      from_name: '',  // Name of the person filling the form
      from_email: '', // Email of the person filling the form
      message: '',    // The message content
    },
    validationSchema: Yup.object({
      from_name: Yup.string()
        .min(2, 'Name must be at least 2 characters')
        .required('Name is required'),
      from_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      message: Yup.string()
        .min(10, 'Message must be at least 10 characters')
        .required('Message is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      emailjs.send('service_zdcd17m', 'template_sqtdw9t', values, 'wC1r-0eHwCvylBbd9')
        .then((result) => {
          toast.success('Message sent successfully!');
          resetForm();
        })
        .catch((error) => {
          toast.error('Failed to send message. Please try again.');
        });
    },
  });

  return (
    <section id="contact" className="bg-blue-900 py-16">
      <div className="max-w-7xl mx-auto px-6 md:px-10">
        <div className="text-white text-center mb-12">
          <h3 className="text-3xl font-bold">Get In Touch</h3>
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-12">
          <div className="flex flex-col justify-center md:items-start items-center text-white space-y-4 md:h-full">
            <p className="text-base mt-2 max-w-2xl text-center md:text-left w-5/6">
              We’d love to hear from you! Reach out to explore how our solutions can elevate your business.
            </p>
            <div className="flex items-center md:justify-start">
              <img src={PhoneIcon} alt="Phone" className="w-5 h-5 mr-2" />
              <span className="text-base">+27 71 333 6663</span>
            </div>
            <div className="flex items-center md:justify-start">
              <img src={MailIcon} alt="Mail" className="w-5 h-5 mr-2" />
              <span className="text-base">Sales@CirrusBridge.com</span>
            </div>
            <div className="flex items-center md:justify-start">
              <img src={LocationIcon} alt="Location" className="w-5 h-5 mr-2" />
              <span className="text-base">South Africa</span>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md w-full md:w-2/3 lg:w-1/2">
            <form onSubmit={formik.handleSubmit}>
              <h4 className="text-xl font-bold text-gray-800 mb-4">Contact Us</h4>
              <div className="mb-4">
                <label htmlFor="from_name" className="block text-gray-700 mb-1">Name</label>
                <input
                  type="text"
                  id="from_name"
                  name="from_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.from_name}
                  className={`w-full p-2 border rounded ${formik.touched.from_name && formik.errors.from_name ? 'border-red-500' : 'border-gray-300'}`}
                />
                <div className="min-h-[1rem] mt-1">
                  {formik.touched.from_name && formik.errors.from_name ? (
                    <div className="text-red-500 text-sm">{formik.errors.from_name}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="from_email" className="block text-gray-700 mb-1">Email</label>
                <input
                  type="email"
                  id="from_email"
                  name="from_email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.from_email}
                  className={`w-full p-2 border rounded ${formik.touched.from_email && formik.errors.from_email ? 'border-red-500' : 'border-gray-300'}`}
                />
                <div className="min-h-[1rem] mt-1">
                  {formik.touched.from_email && formik.errors.from_email ? (
                    <div className="text-red-500 text-sm">{formik.errors.from_email}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-700 mb-1">Message</label>
                <textarea
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className={`w-full p-2 border rounded ${formik.touched.message && formik.errors.message ? 'border-red-500' : 'border-gray-300'}`}
                />
                <div className="min-h-[1rem] mt-1">
                  {formik.touched.message && formik.errors.message ? (
                    <div className="text-red-500 text-sm">{formik.errors.message}</div>
                  ) : null}
                </div>
              </div>
              <button type="submit" className="bg-blue-900 text-white py-2 px-3 rounded hover:bg-blue-800 w-full">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />

    </section>
  );
};

export default ContactInformation;
