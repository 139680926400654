import React from 'react';
import { Link } from 'react-scroll';
import { TransparentLogo } from '../assets/images';

const Navbar = () => {
  const headerHeight = 64;

  return (
    <nav className="fixed top-0 w-full bg-white flex items-center p-4 shadow-md">
      <Link
        to="hero"
        smooth={true}
        duration={700}
        offset={-headerHeight}
        className="cursor-pointer hover:text-blue-500"
      >
        <div className="flex items-center ml-6 mr-6">
          <img src={TransparentLogo} alt="Logo" className="h-12 mr-2" />
          <span className="text-xl font-bold text-gray-800">Cirrus Bridge</span>
        </div>
      </Link>

      <ul className="hidden md:flex space-x-10 text-gray-800 ml-6 mt-0.5">
        <li>
          <Link
            to="services"
            smooth={true}
            duration={700}
            offset={-headerHeight}
            className="cursor-pointer hover:text-blue-500"
          >
            Our Services
          </Link>
        </li>
        <li>
          <Link
            to="values"
            smooth={true}
            duration={700}
            offset={-headerHeight}
            className="cursor-pointer hover:text-blue-500"
          >
            Our Core Values
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            smooth={true}
            duration={700}
            offset={-headerHeight}
            className="cursor-pointer hover:text-blue-500"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
