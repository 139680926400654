import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import HeroSection from './Components/hero-section';
import ServicesSection from './Components/our-services';
import CoreValuesSection from './Components/core-values';
import ContactInformation from './Components/contact-us';
import Footer from './Components/footer';
import PageLoadAnimation from './Components/Page-Load';
import ClientsSection from './Components/our-clients';

function App() {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  return (
    <>
    {!isAnimationComplete && <PageLoadAnimation onComplete={() => setIsAnimationComplete(true)} />}
    <Navbar />
    <HeroSection />
    <ServicesSection/>
    {/* <ClientsSection/> */}
    <CoreValuesSection/>
    <ContactInformation/>
    <Footer/>
  </>
  );
}

export default App;
