import React from 'react';
import { Idea, Target, Trusted } from '../assets/images';

const CoreValuesSection: React.FC = () => {
  return (
    <section id="values" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-16">Our Core Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <img src={Idea} alt="Innovation" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Innovation</h3>
            <p className="text-gray-600 mt-2">
            We believe in pushing the boundaries of technology, continuously innovating to deliver cutting-edge solutions to our clients.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Trusted} alt="Trust" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Trust</h3>
            <p className="text-gray-600 mt-2">
                Building long-lasting relationships through honesty and transparency, we earn the trust of our clients by delivering on our promises.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Target} alt="Future-Focused" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-bold text-gray-800">Future-Focused</h3>
            <p className="text-gray-600 mt-2">
            We stay ahead of industry trends, ensuring our clients are always prepared for the opportunities and challenges that lie ahead.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreValuesSection;
