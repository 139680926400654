import React from 'react';
import { Cloud, CodeAudit, SoftwareDevelopment } from '../assets/images';

const ServicesSection: React.FC = () => {
  return (
    <section id="services" className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-6 md:px-12">
        <h2 className="text-3xl md:text-3xl font-bold text-gray-800 mb-8 text-center md:text-left">
          Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
          <div className="flex flex-col ">
            <img src={SoftwareDevelopment} alt="Service 1" className="w-full h-60 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-bold text-gray-800 ">Software Development</h3>
            <p className="text-gray-600 mt-2 ">
            We create responsive, user-friendly mobile and web apps that deliver seamless experiences across all devices.           </p>
          </div>
          <div className="flex flex-col ">
            <img src={Cloud} alt="Service 2" className="w-full h-60 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-bold text-gray-800 ">Cloud Consulting</h3>
            <p className="text-gray-600 mt-2 ">
            We offer certified cloud consulting to design, deploy, and optimize cloud solutions that enhance your organization’s efficiency and scalability.      </p>
          </div>
          <div className="flex flex-col ">
            <img src={CodeAudit} alt="Service 3" className="w-full h-60 object-cover rounded-lg mb-4" />
            <h3 className="text-xl font-bold text-gray-800 ">Code Audit</h3>
            <p className="text-gray-600 mt-2 ">
            Our Code Audit identifies issues, inefficiencies, and security risks in your codebase, providing recommendations to ensure best practices and optimize performance.  </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
